body {
  overflow-x: hidden;
}

@media screen and (max-width: 576px) {
  .truncate-xs-only {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

/* TEMPORARY FIX CAUSE HOTRELOAD IS BROKEN */
iframe:not(#jsd-widget) {
  display: none !important;
}

#jsd-widget {
  z-index: 60 !important;
}

a {
  color: inherit;
}

a:hover {
  /*This color does not exist in _variables.less - will fix the style guide later*/
  color: #40a9ff;
}

ul {
  border-radius: 0;
}

.ant-tabs-nav-more {
  padding: 8px 16px;
  padding-right: 16px;
}

/*3 dots that show dropdown on tabs when screen is too thin.*/
.ant-tabs-dropdown-menu-item:has(.is-watchlist-single-tab-item) {
  margin: 0;
  padding: 0;
}

.ant-tabs-dropdown-menu-root {
  padding: 4px 0px;
}

.ant-tabs-dropdown-menu-root .is-watchlist-single-tab-item {
  margin: 0;
  padding: 4px 16px;
  display: inline-block;
}

.ant-tabs-dropdown-menu-root .watchlist-dropdown {
  padding: 0px 4px;
}

.ant-tabs-dropdown-menu-root li > span > span:empty {
  display: none;
}

.ant-layout-content {
  background-color: #f0f2f5;
}
